

















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { UserCommunication, UsersProfileStat, } from '../../includes/types/UserProfile/types'
import CoreUsersStatUser from '../CommunityDashboard/components/CoreUsersStatUser.vue'

@Component({
  components: {
    CoreUsersStatUser
  }
})
export default class UserProfileInteracted extends Vue {
  @Prop() users!:UsersProfileStat['users']

  @Prop() source!: UserCommunication
}
